import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import { AppContext } from "~context/AppContext";

export default function Layout({ children, className }) {
  return (
    <AppContext.Consumer>
      {appContext => (
        <>
          <Helmet
            bodyAttributes={{ class: `${appContext.dark ? `dark` : ``}` }}
          />

          <main className={`layout ${className}`}>{children}</main>

          <footer className="w-full grid">
            <p className="footer text-bone opacity-50 b2 w-full flex items-center grid-end--12">
              Site by&nbsp;
              <a
                className="underline"
                href="https://seb.bailouni.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Seb
              </a>
              .
            </p>
          </footer>
        </>
      )}
    </AppContext.Consumer>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired
};
